import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  IdentifyingClient,
  DomainTranslatedDto,
  ExpectationDto,
  TalentScanDto
} from '../../shared/api/identifying-api.generated';
import { AccountDto, AdministratorClient } from '../../shared/api/administrator-api.generated';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, combineLatest, BehaviorSubject, of, from } from 'rxjs';
import { filter, switchMap, map, distinctUntilChanged, catchError, mergeMap, debounceTime, tap } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserSettingsService } from '../../core/services/user-settings.service';
import { PortalService } from '../../core/services/portal.service';
import { ExpectationTranslatedDto } from '../../shared/api/tagging-api.generated';
import { environment } from '../../../environments/environment';
import { BatchActionService, BatchSelectionType } from '../../core/services/batch-action.service';
import { DeleteModalComponent, DeleteModalProps } from '../../shared/components/delete-modal/delete-modal.component';
import { TalentScansFilterService, TalentScansFilter } from '../talentscans-filter.service';

export type Expectation = ExpectationDto & { expectationTypeId: number, name: string, iconUrl: string };
export type TalentScan = TalentScanDto & { domains: DomainTranslatedDto[], expectations: Expectation[] };

@Component({
  selector: 'kazi-talentscans',
  templateUrl: './talentscans.component.html',
  styleUrls: ['./talentscans.component.scss']
})
export class TalentScansComponent implements OnInit, OnDestroy {

  talents: TalentScan[];
  totalCount: number;
  sortOn: string;
  actionBarVisible: boolean;
  filter: TalentScansFilter;
  loading: boolean;

  private page = 1;
  private pageSize = environment.api.pageSize;
  private fetch$: BehaviorSubject<boolean>;
  private subscriptions: Subscription[];

  constructor(private identifyingClient: IdentifyingClient,
    private administratorClient: AdministratorClient,
    private userSettingsService: UserSettingsService,
    private portalService: PortalService,
    private batchActionService: BatchActionService,
    private modalService: BsModalService,
    private translate: TranslateService,
    private talentsFilterService: TalentScansFilterService) {
    this.talents = [];
    this.subscriptions = [];

    this.fetch$ = new BehaviorSubject(false);
    this.sortOn = '-CreatedOn';
  }

  ngOnInit() {

    const talentsSub = combineLatest(this.fetch$,
      this.talentsFilterService.filter$,
      this.userSettingsService.currentAccount$,
      this.portalService.expectations$,
      this.portalService.domains$)
      .pipe(
        filter(([fetch, talentsFilter, account, expectations, domains]) => fetch && !!account && !!expectations && !!domains),
        debounceTime(500),
        switchMap(([fetch, talentsFilter, account, expectations, domains]:
          [boolean, TalentScansFilter, AccountDto, ExpectationTranslatedDto[], DomainTranslatedDto[]]) => {
          const offset = (this.page - 1) * this.pageSize;
          this.loading = true;
          return this.administratorClient.getTalentScansForAccount(
            account.id, undefined /* externalRef */, undefined /* shouldHaveExpectations */,
            true /* includeExpectations */,
            talentsFilter.teamRoleIds, talentsFilter.workValueIds,
            offset, this.pageSize, this.sortOn,
            talentsFilter.search, talentsFilter.domainIds, undefined /* atsClientId */).pipe(
              map((talents: TalentScan[]) => {
                talents.forEach(t => {
                  t.expectations = this.mapExpectations(t, expectations);
                  t.domains = this.mapDomains(t, domains);
                });
                return talents;
              }),
              catchError(err => of([]))
            );
        }),
      ).subscribe(talents => {
        this.loading = false;
        this.getTotalNumberOfTalentScansForAccount(this.userSettingsService.currentAccount$.value, this.talentsFilterService.filter$.value)
          .subscribe(totalCount => this.totalCount = totalCount);
        Array.prototype.push.apply(this.talents, talents);
        this.fetch$.next(false);
      });

    const accountSub = this.userSettingsService
      .currentAccount$
      .pipe(filter(p => !!p), distinctUntilChanged())
      .subscribe(account => this.reset());

    const batchDeleteSub = this.batchActionService
      .delete$
      .pipe(filter(res => res.selectionType === BatchSelectionType.Talents))
      .subscribe(res => this.showBatchDeleteConfirmationModal(res.selection));

    const batchActionBarVisibleSub = this.batchActionService
      .actionBarVisible$
      .subscribe(actionBarVisible => this.actionBarVisible = actionBarVisible);

    const filterSub = this.talentsFilterService
      .filter$
      .subscribe(talentsFilter => {
        this.filter = talentsFilter;
        this.reset();
      });

    const totalCountSub = combineLatest(this.userSettingsService.currentAccount$,
      this.talentsFilterService.filter$)
      .pipe(
        filter(([account, talentsFilter]) => !!account),
        switchMap(([account, talentsFilter]: [AccountDto, TalentScansFilter]) =>
          this.administratorClient.getTotalNumberOfTalentScansForAccount(
            account.id, undefined /* shouldHaveExpectations */,
            talentsFilter.teamRoleIds, talentsFilter.workValueIds,
            talentsFilter.search, talentsFilter.domainIds)
        )
      ).subscribe(totalCount => this.totalCount = totalCount);

    this.subscriptions.push(talentsSub);
    this.subscriptions.push(accountSub);
    this.subscriptions.push(batchDeleteSub);
    this.subscriptions.push(batchActionBarVisibleSub);
    this.subscriptions.push(filterSub);
    this.subscriptions.push(totalCountSub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getTotalNumberOfTalentScansForAccount(account: AccountDto, talentsFilter: TalentScansFilter) {
    return this.administratorClient.getTotalNumberOfTalentScansForAccount(
      account.id, undefined /* shouldHaveExpectations */,
      talentsFilter.teamRoleIds, talentsFilter.workValueIds,
      talentsFilter.search, talentsFilter.domainIds)
  }

  getTalents() {
    this.page++;
    this.fetch$.next(true);
  }

  sort(sortOn: string) {
    this.sortOn = sortOn;
    this.reset();
  }

  onDelete(talent: TalentScan) {
    const index = this.talents.indexOf(talent);
    if (index >= 0) {
      this.talents.splice(index, 1);
      this.totalCount--;
    }
  }

  workValuesChanged(workValues: string[]) {
    this.filter.workValues = workValues;
    this.talentsFilterService.filter$.next(this.filter);
  }

  teamRolesChanged(teamRoles: string[]) {
    this.filter.teamRoles = teamRoles;
    this.talentsFilterService.filter$.next(this.filter);
  }

  domainsChanged(domains: string[]) {
    this.filter.domains = domains;
    this.talentsFilterService.filter$.next(this.filter);
  }

  clearSearch() {
    this.filter.search = '';
    this.searchChanged('');
  }

  searchChanged(search: string) {
    this.talentsFilterService.filter$.next(this.filter);
  }

  private reset() {
    this.batchActionService.deSelectAll();
    this.page = 1;
    this.talents.splice(0);
    this.totalCount = 0;
    this.fetch$.next(true);
  }

  private showBatchDeleteConfirmationModal(talentIds: Array<string>) {
    const count = talentIds.length;
    const header = this.translate.instant('talents.delete_multiple_modal_header', { count });
    const message = this.translate.instant('talents.delete_multiple_modal_content', {
      count,
      accountName: this.userSettingsService.currentAccount$.getValue().name
    });
    const props = new DeleteModalProps(header, message);
    const initialState = { props };

    this.modalService.show(DeleteModalComponent, { initialState });

    const deleteSub = props.confirm$.subscribe(() => this.deleteTalents(talentIds));
    this.subscriptions.push(deleteSub);
  }

  private deleteTalents(talentIds: Array<string>) {

    this.identifyingClient.deleteTalentScans(talentIds.join(','))
      .subscribe(() => {
        this.batchActionService.deSelectAll();
        this.batchActionService.actionBarVisible$.next(false);

        talentIds.forEach(id => {
          const talent = this.talents.filter(t => t.id === id)[0];
          this.onDelete(talent);
        });
      });
  }

  private mapExpectations(talent: TalentScan, allExpectations: ExpectationTranslatedDto[]): Expectation[] {
    if (!talent.expectations) {
      return [];
    }

    const lang = this.translate.currentLang;
    return talent.expectations.map((comp: Expectation) => {
      const translatedExpectation = allExpectations.filter(c => c.id === comp.expectationId)[0];
      comp.expectationTypeId = translatedExpectation.expectationTypeId;
      comp.name = translatedExpectation.name[lang];
      comp.iconUrl = translatedExpectation.iconUrl;
      return comp;
    });
  }

  private mapDomains(talent: TalentScan, allDomains: DomainTranslatedDto[]): DomainTranslatedDto[] {
    if (!talent.domainIds) {
      return [];
    }

    return talent.domainIds.map(domainId => allDomains.filter(dom => dom.id === domainId)[0]);
  }
}
